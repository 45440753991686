import { useStaticQuery, graphql } from "gatsby"

export const useProducts = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryProducts {
        allContentfulProductos {
          edges {
            node {
              title
              paragraph1 {
                paragraph1
              }
              node_locale
            }
          }
        }
      }
    `
  )
  return data.allContentfulProductos.edges.map(i => ({
    lang: i.node.node_locale,
    title: i.node.title,
    paragraph1: i.node.paragraph1.paragraph1,
  }))
}
