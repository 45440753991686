import React from "react"
import { CardVerticalSmallProducts, CardXL } from "../elements/Cards"

import { useProducts } from "../../utils/useProductsSection"
import { useLanguageContext } from "../../utils/LanguageContext"
import { useProductsCards } from "../../utils/useProductsCards"

const Products = () => {
  const data = useProducts()
  const cards = useProductsCards()
  console.log(cards)

  const { toggle } = useLanguageContext()

  const langFilteredEs = data.filter(i => i.lang === "es")
  const langFilteredEn = data.filter(i => i.lang === "en")

  const cardFilteredEs = cards.filter(i => i.lang === "es")
  const cardFilteredEn = cards.filter(i => i.lang === "en")

  console.log(cardFilteredEs)

  return (
    <div className="flex flex-col items-center xl:items-start md:pb-16">
      <div className="max-w-sm xl:max-w-full xl:w-full flex flex-col items-center text-center lg:text-left lg:items-start mx-auto lg:mx-0">
        <h1 className="h1-mobile lg:hidden max-w-md lg:max-w-full">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <h1 className="h1-desktop hidden lg:block lg:pt-8">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <p className="py-4 max-w-md xl:max-w-full xl:w-full">
          {toggle === "es"
            ? langFilteredEs.map(i => i.paragraph1)
            : langFilteredEn.map(i => i.paragraph1)}
        </p>
      </div>
      <div className="xl:hidden flex flex-col xl:flex-row lg:flex-wrap items-center justify-center sm:justify-around lg:justify-end w-full">
        {toggle === "es"
          ? cardFilteredEs.map(i => (
              <CardVerticalSmallProducts
                title={i.title}
                description={i.description}
                descriptionLong={i.descriptionLong}
                image={i.image}
              />
            ))
          : cardFilteredEn.map(i => (
              <CardVerticalSmallProducts
                title={i.title}
                description={i.description}
                descriptionLong={i.descriptionLong}
                image={i.image}
              />
            ))}
      </div>
      <div className="hidden xl:flex flex-col xl:flex-row lg:flex-wrap flex-shrink-1 items-center justify-center sm:justify-around lg:justify-center lg:pt-6">
        {toggle === "es"
          ? cardFilteredEs.map(i => (
              <CardXL
                title={i.title}
                description={i.description}
                descriptionLong={i.descriptionLong}
                image={i.image}
              />
            ))
          : cardFilteredEn.map(i => (
              <CardXL
                title={i.title}
                description={i.description}
                descriptionLong={i.descriptionLong}
                image={i.image}
              />
            ))}
      </div>
    </div>
  )
}

export default Products
