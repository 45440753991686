import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Products from "../components/sections/Products"

const ProductsPage = () => {
  return (
    <Layout>
      <Seo title="Productos" />
      <Products />
    </Layout>
  )
}

export default ProductsPage
