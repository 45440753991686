import { useStaticQuery, graphql } from "gatsby"

export const useProductsCards = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryCardProducts {
        allContentfulCardsProductos {
          edges {
            node {
              title
              node_locale
              paragraph1 {
                paragraph1
              }
              paragraph2 {
                paragraph2
              }
              image {
                gatsbyImageData(
                  width: 400
                  height: 312
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    `
  )
  return data.allContentfulCardsProductos.edges.map(i => ({
    lang: i.node.node_locale,
    title: i.node.title,
    description: i.node.paragraph1.paragraph1,
    descriptionLong: i.node.paragraph2.paragraph2,
    image: i.node.image.gatsbyImageData,
  }))
}
